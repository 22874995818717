.App {
  text-align: left;
}

.App-Container {
  background-color: white;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  width: 60vw;
}

p {
  text-align: left;
  font-size: medium;
}
h1 {
  text-align: left;
}

h2 {
  text-align: left;
}

@media only screen and (max-width: 600px) {
  .App-container {
    width: 95vw;
  }
}
